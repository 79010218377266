import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/nhavanchuyen/Nhavanchuyen_list'),
  loading: LoadingPage,
})

const PagenhavanchuyenGroup = Loadable({
  loader: () => import('../../pages/nhavanchuyen/Nhavanchuyen_block_list'),
  loading: LoadingPage,
})

const Routes = [
  {
    path: '/admin/nhavanchuyen',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/admin/nhavanchuyen/nhavanchuyen_block',
    exact: true,
    requiredAuth: true,
    component: PagenhavanchuyenGroup,
    layout: MainLayout,
  }
]

export default Routes
