import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/users/Users_list'),
  loading: LoadingPage,
})

const PageAction = Loadable({
  loader: () => import('../../pages/users/Action'),
  loading: LoadingPage,
})

const PageInfo = Loadable({
  loader: () => import('../../pages/users/Info'),
  loading: LoadingPage,
})

const SystemUpdate = Loadable({
  loader: () => import('../../pages/system/System_update'),
  loading: LoadingPage,
})

const Routes = [
  {
    path: '/admin/users',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/admin/users/action',
    exact: true,
    requiredAuth: true,
    component: PageAction,
    layout: MainLayout,
  },
  {
    path: '/admin/users/action/:id',
    exact: true,
    requiredAuth: true,
    component: PageAction,
    layout: MainLayout,
  },
  {
    path: '/admin/users/info',
    exact: true,
    requiredAuth: true,
    component: PageInfo,
    layout: MainLayout,
  },
  {
    path: '/admin/system_update',
    exact: true,
    requiredAuth: true,
    component: SystemUpdate,
    layout: MainLayout,
  },
]

export default Routes
