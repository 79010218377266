import httpRequest from '../../services/httpRequest';

// import global from '../../global';
// const API_URL = global.api_url + 'modules/api/modules_api/';
const API_URL = process.env.REACT_APP_API_ENDPOINT + 'modules/api/modules_api/';


const get_module = async() => {
  return httpRequest.get(API_URL + "get_module");
};

const get_routes = async(id_module, Segment) => {
  let config = {
    params: {
      id_module: id_module,
      Segment: Segment
    },
  }
  return httpRequest.get(API_URL + "get_routes", config);
};

const list_menu_navibar = async() => {
  return httpRequest.get(API_URL + "list_menu_navibar");
};

const ex =  {
  get_module,
  get_routes,
  list_menu_navibar
};

export default ex