import React, { Component } from 'react';
import { render } from 'react-dom';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'


const alert = (type = 'success', $content = 'Alert') => {
  toastr.options = {
      // positionClass : 'toast-top-right',
      // hideDuration: 300,
      // timeOut: 60000
      "closeButton": true,
      "debug": false,
      "positionClass": "toast-bottom-right",
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }
  toastr.clear()
  //setTimeout(() => toastr.success($content), 300)
  switch (type) {
      case 'info':
        toastr.info($content, 'Info');
        break;
      case 'success':
        toastr.success($content , 'Success');
        break;
      case 'warning':
        toastr.warn($content, 'Warning');
        break;
      case 'error':
        toastr.error($content,'Error');
        break;
      default:
        toastr.error($content, 'Error');
    }  
};

export default {
  alert
};

