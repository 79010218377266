import React, { memo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { hasAuth, checkPermission } from './permissionShipment'
//import { hasAuth, checkPermission } from './permission'
import UnauthorizedPage from '../pages/Error/UnauthorizedShipment'

const ShipmentRoute = ({ userInfo, component: Component, ...rest }) => {
  if (hasAuth(userInfo)) {
    //if (checkPermission()) {
      return <Route {...rest} render={() => <Component />} />
    //}

    //return <Route {...rest} render={() => <UnauthorizedPage />} />
  }

  // if(checkPermission(userInfo) === false){
  //   return window.location.replace('/admin');
  // }

  return <Route {...rest} render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />} />
  
}


export default ShipmentRoute
