import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/inventory/Inventory_list'),
  loading: LoadingPage,
})

const Inventory_action = Loadable({
  loader: () => import('../../pages/inventory/action/Inventory_action'),
  loading: LoadingPage,
})


const Routes = [
  {
    path: '/inventory',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/inventory/action',
    exact: true,
    requiredAuth: true,
    component: Inventory_action,
    layout: MainLayout,
  },
  {
    path: '/inventory/action/:id',
    exact: true,
    requiredAuth: true,
    component: Inventory_action,
    layout: MainLayout,
  }
]

export default Routes
