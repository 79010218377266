import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/nhacungcap/Nhacungcap_list'),
  loading: LoadingPage,
})

const PagenhacungcapGroup = Loadable({
  loader: () => import('../../pages/nhacungcap/Nhacungcap_block_list'),
  loading: LoadingPage,
})

const Routes = [
  {
    path: '/nhacungcap',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/nhacungcap/nhacungcap_block',
    exact: true,
    requiredAuth: true,
    component: PagenhacungcapGroup,
    layout: MainLayout,
  }
]

export default Routes
