class System_lib {
	//moi cho hang nghin
	FormatNumber = (str) => {
		if(str === undefined || str === null || str === 0){
			return '0';
		}else{
			if(str < 0){
				var newstr = String(str).replace(/^-+/, '')
			}else{
				var newstr = str
			}

			var strTemp = this.GetNumber(newstr);
			if (strTemp.length <= 3)
				return strTemp;
			var strResult = "";
			
			for (var i = 0; i < strTemp.length; i++)
				strTemp = strTemp.replace(",", "");

			strTemp = strTemp + "";
			if (strTemp.indexOf !== 'undefined') {
				var m = strTemp.lastIndexOf(".");
				if (m === -1) {
					let i = 0;
					for (i = strTemp.length; i >= 0; i--) {
						if (strResult.length > 0 && (strTemp.length - i - 1) % 3 == 0)
							strResult = "," + strResult;
						strResult = strTemp.substring(i, i + 1) + strResult;
					}
				} else {
					var strphannguyen = strTemp.substring(0, strTemp.lastIndexOf("."));
					var strphanthapphan = strTemp.substring(strTemp.lastIndexOf("."), strTemp.length);
					var tam = 0;
					for (var i = strphannguyen.length; i >= 0; i--) {

						if (strResult.length > 0 && tam === 4) {
							strResult = "," + strResult;
							tam = 1;
						}

						strResult = strphannguyen.substring(i, i + 1) + strResult;
						tam = tam + 1;
					}
					strResult = strResult + strphanthapphan;
				}
				
				if(str < 0){
					return '-'+ strResult;
				}else{
					return strResult;
				}
			} else {
				return 0;
			}
		}
		
	}

	GetNumber = (str) => {
		var count = 0;
		var ipubl_date = '';
		if(str !== undefined || str !== null || str !== 0){
			for (var i = 0; i < str.length; i++) {
				var temp = str.substring(i, i + 1);
				if (!(temp === "," || temp === "." || (temp >= 0 && temp <= 9))) {
					//alert(inputnumber);
					return str.substring(0, i);
				}
				if (temp === " ")
					return str.substring(0, i);
				if (temp === ".") {
					if (count > 0)
						return str.substring(0, ipubl_date);
					count++;
				}
			}
		}
		return str;

	}
	// het moi hang nghin

	number_format = (number, decimals, dec_point, thousands_sep) => {
		var n = number, prec = decimals;

		var toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);
			return (Math.round(n * k) / k).toString();
		};

		n = !isFinite(+n) ? 0 : +n;
		prec = !isFinite(+prec) ? 0 : Math.abs(prec);
		var sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep;
		var dec = (typeof dec_point === 'undefined') ? '.' : dec_point;

		var s = (prec > 0) ? toFixedFix(n, prec) : toFixedFix(Math.round(n), prec);
		//fix for IE parseFloat(0.55).toFixed(0) = 0;

		var abs = toFixedFix(Math.abs(n), prec);
		var _, i;

		if (abs >= 1000) {
			_ = abs.split(/\D/);
			i = _[0].length % 3 || 3;

			_[0] = s.slice(0, i + (n < 0)) +
				_[0].slice(i).replace(/(\d{3})/g, sep + '$1');
			s = _.join(dec);
		} else {
			s = s.replace('.', dec);
		}

		var decPos = s.indexOf(dec);
		if (prec >= 1 && decPos !== -1 && (s.length - decPos - 1) < prec) {
			s += new Array(prec - (s.length - decPos - 1)).join(0) + '0';
		}
		else if (prec >= 1 && decPos === -1) {
			s += dec + new Array(prec).join(0) + '0';
		}
		return s;
	}

	lang_vi_datepicker = () => {
		const months = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7', 'Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12']
		const days = ['H', 'B', 'T', 'N', 'S', 'B', 'C']

		const locale = {
			localize: {
				month: n => months[n],
				day: n => days[n]
			},
			formatLong: {}
		}

		return locale;
	}

	//begin count decimals
	round_decimal = (value, exp) => {
		if (typeof exp === 'undefined' || +exp === 0)
			return Math.round(value);

		value = +value;
		exp = +exp;

		if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
			return NaN;

		// Shift
		value = value.toString().split('e');
		value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));

		// Shift back
		value = value.toString().split('e');
		return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
	}

	removeVietnameseTones = (str) => {
		str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
		str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
		str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
		str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
		str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
		str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
		str = str.replace(/đ/g,"d");
		str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
		str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
		str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
		str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
		str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
		str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
		str = str.replace(/Đ/g, "D");
		// Some system encode vietnamese combining accent as individual utf-8 characters
		// Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
		str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
		str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
		// Remove extra spaces
		// Bỏ các khoảng trắng liền nhau
		str = str.replace(/ + /g," ");
		str = str.trim();
		// Remove punctuations
		// Bỏ dấu câu, kí tự đặc biệt
		str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
		return str;
	}

	ChangeToSlug = (title) => {
		let slug;
	
		//Lấy text từ thẻ input title 
		//title = document.getElementById("title").value;
	
		//Đổi chữ hoa thành chữ thường
		slug = title.toLowerCase();
	
		//Đổi ký tự có dấu thành không dấu
		slug = slug.replace(/á|à|ả|ạ|ã|ă|ắ|ằ|ẳ|ẵ|ặ|â|ấ|ầ|ẩ|ẫ|ậ/gi, 'a');
		slug = slug.replace(/é|è|ẻ|ẽ|ẹ|ê|ế|ề|ể|ễ|ệ/gi, 'e');
		slug = slug.replace(/i|í|ì|ỉ|ĩ|ị/gi, 'i');
		slug = slug.replace(/ó|ò|ỏ|õ|ọ|ô|ố|ồ|ổ|ỗ|ộ|ơ|ớ|ờ|ở|ỡ|ợ/gi, 'o');
		slug = slug.replace(/ú|ù|ủ|ũ|ụ|ư|ứ|ừ|ử|ữ|ự/gi, 'u');
		slug = slug.replace(/ý|ỳ|ỷ|ỹ|ỵ/gi, 'y');
		slug = slug.replace(/đ/gi, 'd');
		//Xóa các ký tự đặt biệt
		slug = slug.replace(/\`|\~|\!|\@|\#|\||\$|\%|\^|\&|\*|\(|\)|\+|\=|\,|\.|\/|\?|\>|\<|\'|\"|\:|\;|_/gi, '');
		//Đổi khoảng trắng thành ký tự gạch ngang
		slug = slug.replace(/ /gi, "-");
		//Đổi nhiều ký tự gạch ngang liên tiếp thành 1 ký tự gạch ngang
		//Phòng trường hợp người nhập vào quá nhiều ký tự trắng
		slug = slug.replace(/\-\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-\-/gi, '-');
		slug = slug.replace(/\-\-\-/gi, '-');
		slug = slug.replace(/\-\-/gi, '-');
		//Xóa các ký tự gạch ngang ở đầu và cuối
		slug = '@' + slug + '@';
		slug = slug.replace(/\@\-|\-\@|\@/gi, '');
		//In slug ra textbox có id “slug”
		//document.getElementById('slug').value = slug;
		return slug
	}

	makeid = (length) => {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		  	result += characters.charAt(Math.floor(Math.random() * 
	 		charactersLength));
	   	}
	   return result;
	}

	makeidNumberOnly = (length) => {
		var result           = '';
		var characters       = '0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		  	result += characters.charAt(Math.floor(Math.random() * 
	 		charactersLength));
	   	}
	   return result;
	}

	_get_config = (config_name = 'style_discount_default') => {
		if (typeof window !== "undefined") {
			const cf = localStorage.getItem('config')
			
			if(cf !== null){
				const config = JSON.parse(cf.toLowerCase())
				if(config[config_name] !== 'undefined') return config[config_name]
			}
			
		}
		return '';
	}	

	_style_shipment = (style_shipment = 1) => {
		var vl = ''
		parseInt(style_shipment) === 1 ?
			vl = <span>Nháp</span>
		:
		parseInt(style_shipment) === 2 ?
			vl = <span>Chờ báo giá</span>
		:
		parseInt(style_shipment) === 3 ?
			vl = <span className='text-warning'>Đã báo giá</span>
		:
		parseInt(style_shipment) === 4 ?
			vl = <span className='text-warning'>Xác nhận báo giá</span>
		:
		parseInt(style_shipment) === 5 ?
			vl = <span className='text-warning'>Đang lấy hàng</span>
		:
		parseInt(style_shipment) === 6 ?
			vl = <span className='text-warning'>Đang vận chuyển</span>
		:
		parseInt(style_shipment) === 7 ?
			vl = <span className='text-warning'>Chuyển thành công</span>
		:
		parseInt(style_shipment) === 8 ?
			vl = <span className='text-warning'>Hủy đơn</span>
		:
		parseInt(style_shipment) === 9 ?
			vl = <span className='text-warning'>Giao thất bại</span>
		:
		parseInt(style_shipment) === 10 ?
			vl = <span className='text-warning'>Đã gửi các đơn vị vận chuyển</span>
		:
			vl = ''
			
		return vl
	}

	emptyCache = (vesion_app = 1.5) => {
		let check = 0;
		if('caches' in window){
		caches.keys().then((names) => {
				// Delete all the cache files
				names.forEach(name => {
					caches.delete(name);
				})
			});
	
			// Makes sure the page reloads. Changes are only visible after you refresh.
			//window.location.reload(true);
			check = 1;
		}
		return check
	}
}
export default System_lib