import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';

import Routes_navibar from "./Routes_navibar"

function Modules_navibar(props){
    // const [isLoading, setisLoading] = useState(false);
	// const [loading_action, setloading_action] = useState(false);
	// const [mess, setmess] = useState('');

    // const [data_list, setdata_list] = useState([]);
    // const [action_id, setaction_id] = useState('');
    // const [output, setoutput] = useState([]);
    const [display_show, setdisplay_show] = useState(props.active_class);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {

    },[])

    const handMenu = () => {
        setIsOpen(!isOpen);
        setdisplay_show('');
    }
    const active_class = isOpen? 'open' : '';

    const style_submenu = () => {
        if(props.active_class.length || isOpen){
            return true
        }
    }
    return(
        <li className={props.active_class+' '+active_class} key={props.item.id} onClick={handMenu}>
            <Link to={(props.check_has_sub === true)?"#!":'/admin/'+props.item.Segment } className="nav-link">
            <i className="material-icons-outlined">{props.item.code_icon}</i>{props.item.MoName} 
            { (props.check_has_sub === true)?
                <i className="material-icons has-sub-menu">add</i> : ''
            }
            </Link>
            {(props.check_has_sub === true) ? 
                <ul className={"sub-menu"} style={style_submenu()?{display:"block"}:{display:"none"}}>
                    <Routes_navibar module_id={props.item.IDM} Segment={props.item.Segment} routes={props.routes} />
                </ul>
            :''
            }
        </li>
    )
}

export default Modules_navibar;