import Loadable from 'react-loadable'

import BodyLayout from '../../components/layout/BodyLayout'
import LoadingPage from '../../components/layout/Loading'

const t = () => {
  return(
    <>
    t
    </>
  )
}

const MediaMainPage = Loadable({
  //loader: () => import('../../pages/media/test/List_media_main'),
  loader: () => import('../../pages/media_main/List_media'),
  loading: LoadingPage,
})

const MediaMainModulePage = Loadable({
  loader: () => import('../../pages/media_main/List_media_module'),
  loading: LoadingPage,
})

const MediaMainTest = Loadable({
  loader: () => import('../../components/test'),
  loading: t,
})

const MediaMainRoutes = [
  {
    //path: ['/media_main','/media_main/:id'],
    path: '/media_main',
    exact: true,
    requiredAuth: true,
    component: MediaMainPage,
    layout: BodyLayout,
  },
  {
    path: '/media_main_module/:module/:thumbweight/:thumbheight/:style',
    exact: true,
    requiredAuth: true,
    component: MediaMainModulePage,
    layout: BodyLayout,
  },
  {
    path: '/test',
    exact: true,
    requiredAuth: true,
    component: MediaMainTest,
    layout: BodyLayout,
  },
]

export default MediaMainRoutes
