import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'

const DashboardPage = Loadable({
  loader: () => import('../../pages/dashboard/Dashboard'),
  loading: LoadingPage,
})

const dashboardRoutes = [
  {
    path: '/admin',
    exact: true,
    requiredAuth: true,
    component: DashboardPage,
    layout: MainLayout,
  },
  {
    path: '/admin/home',
    exact: true,
    requiredAuth: true,
    component: DashboardPage,
    layout: MainLayout,
  },
]

export default dashboardRoutes
