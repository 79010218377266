import authHeader_token from "../auth-header-token";
import httpRequest from '../../services/httpRequest';
// import global from '../../global';
// const API_URL = global.api_url + 'systems/api/system_config_api/';

const API_URL = process.env.REACT_APP_API_ENDPOINT + 'systems/api/system_config_api/';

const set_url_ajax_list = {
  "url": API_URL + 'ajax_list',
  "type": "POST",
  headers: {
    'Authorization': authHeader_token.authHeader_form().token
  }
}

const action = () => {
  return httpRequest.get(API_URL + "action");
};

const actionfind = (data) => {
  return httpRequest.post(API_URL + "actionfind", JSON.stringify(data));
};


const process_action = (data) => {
  return httpRequest.post(API_URL + "process_action", JSON.stringify(data));
};

const del_data = (data) => {
  return httpRequest.post(API_URL + "delete_data", JSON.stringify(data));
}

const get_config = () => {
  return httpRequest.get(API_URL + "get_config");
};


const exp = {
  action,
  actionfind,
  process_action,
  set_url_ajax_list,
  del_data,
  get_config
};

export default exp;