import LoginPage from '../../pages/users/Login'
import LoginShipmentPage from '../../pages/shipment/Login'
import RegisterPage from '../../pages/shipment/Register'

const authRoutes = [
  {
    path: '/admin/login',
    exact: true,
    restricted: true,
    component: LoginPage,
  },
  {
    path: '/login',
    exact: false,
    restricted: false,
    component: LoginShipmentPage,
  },
  {
    path: '/register',
    exact: false,
    restricted: false,
    component: RegisterPage,
  },
  // {
  //   //path: process.env.ADMIN_URL,
  //   path: '/admin',
  //   exact: true,
  //   restricted: true,
  //   component: LoginPage
  // }
]

export default authRoutes
