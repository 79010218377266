import React from 'react';

function Loading(props){
    return (
      <div className='loader w6r'>
          <div className='spinner-grow text-primary' role='status'>
              <span className='sr-only'><i className="fa fa-spinner fa-spin"></i> Loading...</span>
          </div>
          <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
          </div>
      </div>
    )
}

export default Loading