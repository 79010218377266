import Loadable from 'react-loadable'

import ShipmentLayout from '../../components/layout/ShipmentLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/shipment/Shipment_manager'),
  loading: LoadingPage,
})

const PageShipmentAction = Loadable({
  loader: () => import('../../pages/shipment/Shipment_action'),
  loading: LoadingPage,
})

const PageShipmentChangeInfo = Loadable({
  loader: () => import('../../pages/shipment/Change_info'),
  loading: LoadingPage,
})

const PageShipmentVIew = Loadable({
  loader: () => import('../../pages/shipment/Shipment_view'),
  loading: LoadingPage,
})



const Routes = [
  {
    path: '/',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageMain,
    layout: ShipmentLayout,
  },
  {
    path: '/home',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageMain,
    layout: ShipmentLayout,
  },
  {
    path: '/shipment',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageMain,
    layout: ShipmentLayout,
  },
  {
    path: '/shipment/action',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageShipmentAction,
    layout: ShipmentLayout,
  },
  {
    path: '/shipment/action/:id',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageShipmentAction,
    layout: ShipmentLayout,
  },
  {
    path: '/shipment/change_info',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageShipmentChangeInfo,
    layout: ShipmentLayout,
  },
  {
    path: '/shipment/view/:id',
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    component: PageShipmentVIew,
    layout: ShipmentLayout,
  }
  
  // {
  //   path: '/logout',
  //   exact: true,
  //   requiredAuth: false,
  //   requiredCus: true,
  //   component: PageShipmentAction,
  //   render: () => console.log('fff')   
  // },
]

export default Routes
