import authHeader_token from "../auth-header-token";
import httpRequest from '../../services/httpRequest';

const API_URL = process.env.REACT_APP_API_ENDPOINT + 'shipment/api/shipment_api/';


const change_active = (formData) => {
  return httpRequest.post(API_URL+'change_active', JSON.stringify(formData))
}

const action_get = (data) => {
  let config = {
    params: data
  }
  return httpRequest.get(API_URL + "action", config);
};

const action_post = (data) => {
  return httpRequest.post(API_URL+'action', JSON.stringify(data))
};

const action_check = (data) => {
  return httpRequest.post(API_URL+'action_check', JSON.stringify(data))
};

const process_action = (data) => {
  return httpRequest.post(API_URL+'process_action', JSON.stringify(data))
};

const update_data = (data) => {
  return httpRequest.post(API_URL+'update_data', JSON.stringify(data))
  
};

const actionfind = (id) => {
  let config = {
    params: {
      id: id
    }
  }
  return httpRequest.get(API_URL + "actionfind", config);
};

const del = (id) => {
  return httpRequest.delete(API_URL + "delete_data/" + id);
};

const ajax_list = (data) => {
  return httpRequest.get(API_URL + "ajax_list", JSON.stringify(data) );
};

const set_url_ajax_list = (mahoadon,tensp,datetimefrom,datetimeto,style_shipment) => {
  const config = {
      "url": API_URL + 'list_shipment?mahoadon='+mahoadon+'&tensp='+tensp+'&datetimefrom='+datetimefrom+'&datetimeto='+datetimeto+'&style_shipment='+style_shipment,
      "type": "POST",
      headers: {
      'Authorization': authHeader_token.authHeader_form().token
    }
  }
  return config
}



const get_hisNhapkho = (nhacc_id = 0) => {
  const config = {
      "url": API_URL + 'get_hisNhapkho?nhacc_id='+nhacc_id,
      "type": "POST",
      headers: {
      'Authorization': authHeader_token.authHeader_form().token
    }
  }
  return config
}


const get_hisXuatkho = (nhacc_id = 0) => {
  const config = {
      "url": API_URL + 'get_hisXuatkho?nhacc_id='+nhacc_id,
      "type": "POST",
      headers: {
      'Authorization': authHeader_token.authHeader_form().token
    }
  }
  return config
}

//begin congno
const get_list_congno = (nhacc_id = 0) => {
  const config = {
      "url": API_URL + 'get_list_congno?nhacc_id='+nhacc_id,
      "type": "POST",
      headers: {
      'Authorization': authHeader_token.authHeader_form().token
    }
  }
  return config
}

const get_congno = async (data) => {
  return httpRequest.post(API_URL + "get_congno", (data) );
};

const thanhtoancongno = async (data) => {
  return httpRequest.post(API_URL + "thanhtoancongno", (data) );
};

const del_thanhtoan_congno = (id) => {
  return httpRequest.delete(API_URL + "del_thanhtoan_congno/" + id);
};

//new
const get_change_info = () => {
  let config = {}
  return httpRequest.get(API_URL + "change_info", config);
};

const post_change_info = (data) => {
  return httpRequest.post(API_URL + "change_info", data);
};

const getinfo = () => {
  return httpRequest.post(API_URL + "getinfo");
};

const action = (data) => {
  return httpRequest.post(API_URL + "action", JSON.stringify(data));
};

const get_edit_shipment = (data) => {
  return httpRequest.post(API_URL + "get_edit_shipment", JSON.stringify(data));
};

const view_info_order = (data) => {
  return httpRequest.post(API_URL + "view_info_order", JSON.stringify(data));
};

const ajax_list_order_detail = (id_order) => {
  let config = {
      "url": API_URL + 'ajax_list_order_detail?id_order='+id_order,
      "type": "POST",
      headers: {
      'Authorization': authHeader_token.authHeader_form().token
    }
  }
  return config;
}

const update_style_shipment = (data) => {
  return httpRequest.post(API_URL + "update_style_shipment", JSON.stringify(data));
};

const update_email = (data) => {
  return httpRequest.post(API_URL + "update_email", JSON.stringify(data));
};


const get_datetime = (data = null) => {
  return httpRequest.post(API_URL + "get_datetime", JSON.stringify(data));
};

const list_order_detail = (data = null) => {
  return httpRequest.post(API_URL + "list_order_detail", JSON.stringify(data));
};

const exp = {
  change_active,
  action_get,
  action_post,
  action_check,
  process_action,
  update_data,
  actionfind,
  del,
  ajax_list,
  set_url_ajax_list,
  get_hisNhapkho,
  get_hisXuatkho,
  get_list_congno,
  get_congno,
  thanhtoancongno,
  del_thanhtoan_congno,
  get_change_info,
  post_change_info,
  getinfo,
  action,
  get_edit_shipment,
  view_info_order,
  ajax_list_order_detail,
  update_style_shipment,
  update_email,
  get_datetime,
  list_order_detail
};

export default exp;