import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/shops/Shops_list'),
  loading: LoadingPage,
})
const PageAhopsAction = Loadable({
  loader: () => import('../../pages/shops/Action'),
  loading: LoadingPage,
})

const PageShops_category_list = Loadable({
  loader: () => import('../../pages/shops/Shops_category_list'),
  loading: LoadingPage,
})

const PageShops_category_list_tree = Loadable({
  loader: () => import('../../pages/shops/Shops_category_list_tree'),
  loading: LoadingPage,
})

const PageShops_category_action = Loadable({
  loader: () => import('../../pages/shops/Action_category'),
  loading: LoadingPage,
})


const Routes = [
  {
    path: '/shops',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/shops/action',
    exact: true,
    requiredAuth: true,
    component: PageAhopsAction,
    layout: MainLayout,
  },
  {
    path: '/shops/action/:id',
    exact: true,
    requiredAuth: true,
    component: PageAhopsAction,
    layout: MainLayout,
  },
  {
    path: '/shops/shops_category',
    exact: true,
    requiredAuth: true,
    component: PageShops_category_list,
    layout: MainLayout,
  },
  {
    path: '/shops/shops_category_tree',
    exact: true,
    requiredAuth: true,
    component: PageShops_category_list_tree,
    layout: MainLayout,
  },
  {
    path: '/shops/shops_category/:id',
    exact: true,
    requiredAuth: true,
    component: PageShops_category_list,
    layout: MainLayout,
  },
  {
    path: '/shops/shops_category_action',
    exact: true,
    requiredAuth: true,
    component: PageShops_category_action,
    layout: MainLayout,
  },
  {
    path: '/shops/shops_category_action/:id',
    exact: true,
    requiredAuth: true,
    component: PageShops_category_action,
    layout: MainLayout,
  },
]

export default Routes
