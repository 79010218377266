import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/kho/Kho_list'),
  loading: LoadingPage,
})

const PageKhoNhap = Loadable({
  loader: () => import('../../pages/kho/Kho_nhap_list'),
  loading: LoadingPage,
})

const PageKhoXuat = Loadable({
  loader: () => import('../../pages/kho/Kho_xuat_list'),
  loading: LoadingPage,
})

const PageNhapHangKho = Loadable({
  loader: () => import('../../pages/kho/Kho_nhapkho'),
  loading: LoadingPage,
})

const PageTraHangNhap = Loadable({
  loader: () => import('../../pages/kho/Kho_trahangnhap'),
  loading: LoadingPage,
})

const testPage = Loadable({
  loader: () => import('../../pages/kho/Test'),
  loading: LoadingPage,
})

const Routes = [
  {
    path: '/admin/kho',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_nhap',
    exact: true,
    requiredAuth: true,
    component: PageKhoNhap,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_xuat',
    exact: true,
    requiredAuth: true,
    component: PageKhoXuat,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_nhapkho',
    exact: true,
    requiredAuth: true,
    component: PageNhapHangKho,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_nhapkho/:id',
    exact: true,
    requiredAuth: true,
    component: PageNhapHangKho,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_trahangnhap',
    exact: true,
    requiredAuth: true,
    component: PageTraHangNhap,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/kho_trahangnhap/:id',
    exact: true,
    requiredAuth: true,
    component: PageTraHangNhap,
    layout: MainLayout,
  },
  {
    path: '/admin/kho/test',
    exact: true,
    requiredAuth: true,
    component: testPage,
    layout: MainLayout,
  },
]

export default Routes
