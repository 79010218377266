import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap'
import ReCAPTCHA from 'react-grecaptcha'
//import { useDispatch, useSelector } from "react-redux";
import Toastr from "../../components/layout/Toastr";
//import { Redirect } from 'react-router-dom';
//import { save_Token } from '../../redux/actionCreators';
//import { connect } from 'react-redux';
import shipment_login_api from "../../api/shipment/shipment_login.api";
import { trans } from "../../i18n";

import AuthHelperMethods from '../../helpers/AuthHelperMethods';

const Register = (props) => {

    const Auth = new AuthHelperMethods();
    const history = useHistory();
    //const [isLoading, setisLoading] = useState(false);
    //const [loading_action, setloading_action] = useState(false);

    const [full_name, setfull_name] = useState('');
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [repassword, setrepassword] = useState('');
    const [phone, setphone] = useState('');
    const [address, setaddress] = useState('');
    const [email, setemail] = useState('');
    const [isCaptchaValid, setisCaptchaValid] = useState(false);

    const [validated, setValidated] = useState(false);
    const [loading, setloading] = useState(false);
    const [noti, setnoti] = useState('');
    const [isLoading_lg, setisLoading_lg] = useState(false);

    const checkBoxRef = useRef();

    useEffect(() => {
        document.title = "Đăng ký mới khách hàng";
        if (Auth.checkLogin_permissionShipment() === true){
            history.replace('/');
        }

    }, [])

    const showrequired = (value) => {
        return (
            <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert" onClick={e => setnoti('')} >×</a>
                <strong>Error!</strong>
                <p>{value}</p>
            </div>
        );
    }

    const register = (event) => {
        setisLoading_lg(true);
        event.preventDefault();
        setnoti('');
        if (username == "") {
            setisLoading_lg(false);
            setnoti(showrequired('Please enter Username'))
        } else if (password == "") {
            setisLoading_lg(false);
            setnoti(showrequired('Please enter password'))
        } else {
            const data = {full_name, username, password, repassword, phone, email, address, isCaptchaValid}
            shipment_login_api.register(data)
                .then(result => {
                    setisLoading_lg(false);
                    if(result.success === true){
                        //Toastr.alert('success', result.alert);
                        history.replace('/login');
                    }else{
                        //Toastr.alert('error', result.alert);
                        setnoti(showrequired(trans(result.alert)))
                        window.scrollTo(0,0);
                    }
                })
        }

    }

    const callback = function () {
        setisCaptchaValid(true)
    };

    const expiredCallback = () => {
        //setisCaptchaValid(true)
    };


    return (
        <>
            <link href="https://fonts.googleapis.com/css?family=Lato:400,700,900&amp;display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&amp;display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
            <link href="/assets/plugins/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css" rel="stylesheet"></link>


            <link href="/assets/css/connect.min.css" rel="stylesheet" />
            <link href="/assets/css/dark_theme.css" rel="stylesheet" />
            <link href="/assets/css/custom.css" rel="stylesheet"></link>



            <div className="auth-page sign-in no-loader">
                <div className="connect-container align-content-stretch d-flex flex-wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="auth-form">
                                    <div className="row">
                                        <div className="col">
                                            <div className="logo-box"><a href="#" className="logo-text">ĐĂNG KÝ KHÁCH HÀNG</a></div>
                                            {noti && <div>{noti}</div>}
                                            <Form noValidate validated={validated} onSubmit={register}>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="full_name" className="control-label">Họ và tên <span className="text-danger">*</span></label>
                                                        <Form.Control type="text" required name="full_name" value={full_name} onChange={(e) => setfull_name(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="phone" className="control-label">Số điện thoại <span className="text-danger">*</span></label>
                                                        <Form.Control type="number" required name="phone" value={phone} onChange={(e) => setphone(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="email" className="control-label">Email <span className="text-danger">*</span></label>
                                                        <Form.Control type="email" required name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="address" className="control-label">Địa chỉ <span className="text-danger">*</span></label>
                                                        <Form.Control as="textarea" required name="address" value={address} onChange={(e) => { setaddress(e.target.value) }} />
                                                        <p className="text-muted m-0 p-0">
                                                            Bưu tá sẽ tới địa chỉ này để lấy hàng
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="username" className="control-label">User name <span className="text-danger">*</span></label>
                                                        <Form.Control type="text" required name="username" value={username} onChange={(e) => setusername(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="password" className="control-label">Mật khẩu <span className="text-danger">*</span></label>
                                                        <Form.Control type="password" required name="password" value={password} onChange={(e) => setpassword(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="col-12">
                                                        <label htmlFor="repassword" className="control-label">Nhắc lại mật khẩu <span className="text-danger">*</span></label>
                                                        <Form.Control type="password" required name="repassword" value={repassword} onChange={(e) => setrepassword(e.target.value)} />
                                                    </div>
                                                </div>

                                                <br/>
                                                <ReCAPTCHA
                                                    sitekey="6LeyxqgiAAAAAI2PLAuWx2_GYzEfS-hDBaIFgXHd"
                                                    callback={callback}
                                                    expiredCallback={expiredCallback}
                                                    locale="vi"
                                                />

                                                <button type="submit" className="btn btn-primary btn-block btn-submit" disabled={isLoading_lg}>
                                                    {trans('Đăng ký mới')} {isLoading_lg && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                </button>
                                                <Link to="/login" className="forgot-link pull-right mgt5" style={{ 'marginTop': '10px' }}><i className="fa fa-user" aria-hidden="true"></i> ĐĂNG NHẬP</Link>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block d-xl-block">
                                <div className="auth-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default Register;

