import React, {useState} from 'react';
import { BrowserRouter as  Link, useHistory } from 'react-router-dom';
//import { history } from "../../helpers/history";

import Header from './Header'; //Include Heder
import Footer from './Footer'; //Include Footer
import Navibar from './Navibar';
import Breadcrumb from './breadcrumb';

import AuthHelperMethods from '../../helpers/AuthHelperMethods';

const MainLayout = ({ children, userInfo, routes }) => {
  const Auth = new AuthHelperMethods();
  let pathname = window.location.pathname;
  //let segment_url = pathname.split("/");
  let setpage = (window.location.pathname === '/admin/login')?true:false
  let class_div1 = (setpage === true)?'':'connect-container align-content-stretch d-flex flex-wrap'
  let class_div2 = (setpage === true)?'':'page-container'

  const [isActive, setActive] = useState(false);
  const [isActive_mb, setActive_mb] = useState('');

  const history = useHistory();
 

  const _handleLogout = () => {
    Auth.logout();
    //window.location.replace("/login");
    history.replace('/admin');
  }

  const handleToggle_sidebar = () => {
    setActive(!isActive);
  };

  const Toggle_sidebar_mobile = () => {
    setActive_mb('small-screen-sidebar-active')
  }

  const Close_Toggle_sidebar_mobile = () => {
    setActive_mb('')
  }

  return (
    <div className={isActive ? "compact-sidebar" : '' +' '+ isActive_mb}>
      <div className={class_div1}>
        <Navibar history={history} logout={_handleLogout} handleToggle_sidebar={handleToggle_sidebar} Close_Toggle_sidebar_mobile={Close_Toggle_sidebar_mobile} isActive={isActive}/>
        <div className={class_div2}>
          <Header history={history} logout={_handleLogout} Toggle_sidebar_mobile={Toggle_sidebar_mobile}/>
          <div className="page-content">
            <Breadcrumb />
            <div className="main-wrapper">
            {children}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}



export default MainLayout
