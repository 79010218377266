import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/customer/Customer_list'),
  loading: LoadingPage,
})

const PageCustomerGroup = Loadable({
  loader: () => import('../../pages/customer/Customer_group_list'),
  loading: LoadingPage,
})

const Routes = [
  {
    path: '/admin/customer',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/admin/customer/customer_group',
    exact: true,
    requiredAuth: true,
    component: PageCustomerGroup,
    layout: MainLayout,
  }
]

export default Routes
