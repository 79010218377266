import AuthHelperMethods from '../helpers/AuthHelperMethods';

const Auth = new AuthHelperMethods();
export const checkPermission = (userInfo) => {
  //if(Auth.checkLogin()){
  if(Auth.getConfirm().result.lev_qt === true && Auth.getConfirm().result.cus_log === false){
    //console.log(Auth.getConfirm().result.cus_log)
    return true
  }
  else{
    return false;
  }
}

export const hasAuth = (userInfo) => {
  //if(Auth.checkLogin() && Auth.getConfirm().result.lev_qt === true && Auth.getConfirm().result.cus_log === false)
  if(Auth.checkLogin_permission() === true)
    return true
  else
    return false;
}
