import React from 'react';
import { BrowserRouter as  Link, useHistory } from 'react-router-dom';

import HeaderShipment from './HeaderShipment';
import Footer from './FooterShipment'; //Include Footer

import AuthHelperMethods from '../../helpers/AuthHelperMethods';

import '../../pages/shipment/style/Shipment.css';

const ShipmentLayout = ({ children, userInfo, routes }) => {
  const Auth = new AuthHelperMethods();
  
  const history = useHistory();
  
  return (
    <>
    <div className="connect-container">
      <div className="page-container_pos">
        <HeaderShipment history={history} />
        <div className="page-shipment">
          <div className="page-content">
            <div className="main-wrapper">
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>    
    </div>
    </>
  )
}



export default ShipmentLayout
