import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'

const PageMain = Loadable({
    loader: () => import('../../pages/reports/Reports_pos'),
    loading: LoadingPage,
})

const Routes = [
    {
      path: '/admin/reports',
      exact: true,
      requiredAuth: true,
      component: PageMain,
      layout: MainLayout,
    }
]
  
export default Routes