//https://stackoverflow.com/questions/60589579/get-closest-parent-element-by-class-name-in-react
import React, { useState, useEffect, useWindowDimensions, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AuthHelperMethods from '../../helpers/AuthHelperMethods';
import { Scrollbars } from 'react-custom-scrollbars';
import Sidebar_api from "../../api/sidebar/sidebar.api"
import Modules_navibar from "./Modules_navibar"
import Loading from "./Loading";

import RenderMenu from './RenderMenu';
//import Routes_navibar from "./Routes_navibar"
//import $ from 'jquery'; 

const Navibar = (props) => {
  const Auth = new AuthHelperMethods();
  const [isLoading, setisLoading] = useState(false);

  const [module, setmodule] = useState([]);
  const [routes, setroutes] = useState([]);
  const location = useLocation();

  const ref_menu = useRef();

  useEffect(() => {
    setisLoading(true);
    Sidebar_api.list_menu_navibar()
        .then(result => {
            setisLoading(false);
            if (typeof result.data !== 'undefined') {
              if(result.data.success === true){
                setmodule(result.data.module);
                //setroutes(result.data.routes);
              }
            }
        })
  },[])



  // let segment_url = location.pathname.split("/");
  // let mid_r = '';
  // for (var i = routes.length - 1; i >= 0; i--) {
  //     mid_r = mid_r + routes[i].MID + ','
  // }
  // var mid_ar = mid_r.split(",");

  const menu = () => {
    return(
      <div className="page-sidebar-inner">
        <ul className="accordion-menu">
            <li className="sidebar-title">
                Tác vụ ứng dụng
            </li>

            {<RenderMenu items={module} isActive={props.isActive} />}
            
            <li className="sidebar-title">
              Log out
            </li>
            { Auth.getToken() !== null ?
            <li className="nav-item active">
              <Link className="nav-link" to="/admin" onClick={props.logout}> <i className="material-icons">logout</i> Đăng xuất</Link>
            </li>
            : '' }
            
        </ul>
      </div>
    )
  }

  const clid_menu = (child) =>{
      
      return(
        <>
        {(!props.isActive)?
        <Scrollbars style={{ height: "87vh" }} autoHide>
          {child}
        </Scrollbars>:child}
        </>
      )
  }

  useOnClickOutside(ref_menu, () => props.Close_Toggle_sidebar_mobile());

  // Hook
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }
  
  return(
    <>
    {isLoading === true ?<Loading />: ''}
    <div className="page-sidebar" ref={ref_menu}>
          <div className="logo-box">
            <Link to="/admin/home" href="#!dashboard" className="logo-text">Nitsoft</Link>
            <a href="#" id="sidebar-close" onClick={props.Close_Toggle_sidebar_mobile}><i className="material-icons">close</i></a> 
            <a href="#" onClick={props.handleToggle_sidebar} id="sidebar-state">
              <i className="material-icons">adjust</i>
              <i className="material-icons compact-sidebar-icon">panorama_fish_eye</i>
              </a>
          </div>
          {clid_menu(menu())}
      </div>
    </>
  )
}



export default Navibar;