import React from 'react'
import { Link } from 'react-router-dom'
import { trans } from '../../i18n'

const UnauthorizedPage = () => (
  <>
    Error auth
    <Link to="/"><button type="primary">{trans('back_home')}</button></Link>
    <Link to="/logout"><button type="primary">{trans('Thoát')}</button></Link>
    Bạn không được sử dụng chức năng này.
  </>
)

export default UnauthorizedPage
