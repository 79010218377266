import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
//import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import { save_Token } from '../../redux/actionCreators';
import { connect} from 'react-redux';
import  Users  from "../../api/users/users.api";
import {trans} from "../../i18n";


import AuthHelperMethods from '../../helpers/AuthHelperMethods';

function Login(props) {
    const Auth = new AuthHelperMethods();
    const history = useHistory();
    //const [isLoading, setisLoading] = useState(false);
	//const [loading_action, setloading_action] = useState(false);

    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [rememberMe, setrememberMe] = useState();
    const [loading, setloading] = useState(false);
    const [user, setuser] = useState(null);
    const [noti, setnoti] = useState('');
    const [token, settoken] = useState(props.myToken);
    const [isLoading_lg, setisLoading_lg] = useState(false);

    const checkBoxRef = useRef();
    // const rmCheck = document.getElementById("rememberMe")
    // const emailInput = document.getElementById("email")
    // const passwordInput = document.getElementById("password")

    useEffect(() => {
        document.title = "Login";
        // if (Auth.loggedIn()){
        //     history.replace('/admin');
        // }
        const loStoreCheck = localStorage.getItem('checkbox')
        if (loStoreCheck && JSON.parse(loStoreCheck.toLowerCase()) === true) {
            setrememberMe(true)
            setusername(localStorage.username)
            setpassword(localStorage.password)
        } else {
            setrememberMe(false)
            setusername("")
            setpassword("")
        }
    },[])

    const showrequired = (value) =>{
    	return (
	      	<div className="alert alert-danger">
	          	<a href="#" className="close" data-dismiss="alert" onClick={ e => setnoti('')} >×</a>
	          	<strong>Error!</strong>
	          	<p>{value}</p>
	      	</div>
	    );
    }

    const onSignIn = (event) => {
    	event.preventDefault();
        setnoti('');
        if(username==""){
            setnoti(showrequired('Please enter Username'))
        }else if(password==""){
            setnoti(showrequired('Please enter password'))
        }else{
            setisLoading_lg(true);
            Users.login(username, password)
                .then(res => {
                    if(res.success == 1){
                        setisLoading_lg(false);
                        Auth.setToken(JSON.stringify(res.result.token));
                        Auth.setTokenRefesh(JSON.stringify(res.result.tokenRefesh))
                        props.mysave_Token(res.result.token);
                        lsRememberMe();
                        //history.replace('/home');
                        window.location.replace('/admin');
                    }else{
                        setisLoading_lg(false);
                        setnoti(showrequired(trans('Username hoặc mật khẩu không đúng')))
                    }
                    
                })
                .catch((error) => {
                	setisLoading_lg(false);
                    if(error == 'TypeError: Network request failed'){
                        alert('Something went wrong', 'Kindly check if the device is connected to stable cellular data plan or WiFi.'); 
                    }
                    alert(error)
                });
        }
        
    }

    const lsRememberMe = () => {
        if (rememberMe === true) {
            localStorage.username = username;
            localStorage.password = password;
            localStorage.checkbox = true;
        } else {
            localStorage.username = "";
            localStorage.password = "";
            localStorage.checkbox = false;
        }
    }

    return (
        <>
            <link href="https://fonts.googleapis.com/css?family=Lato:400,700,900&amp;display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Montserrat:400,500,700&amp;display=swap" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp" rel="stylesheet" />
            <link href="/assets/plugins/bootstrap/css/bootstrap.min.css" rel="stylesheet" />
            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css" rel="stylesheet"></link>


            <link href="/assets/css/connect.min.css" rel="stylesheet" />
            <link href="/assets/css/dark_theme.css" rel="stylesheet" />
            <link href="/assets/css/custom.css" rel="stylesheet"></link>
            


            <div className="auth-page sign-in no-loader">
                <div className="connect-container align-content-stretch d-flex flex-wrap">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="auth-form">
                                    <div className="row">
                                        <div className="col">
                                            <div className="logo-box"><a href="#" className="logo-text">QUẢN TRỊ</a></div>
                                            { noti && <div>{noti}</div> }
                                            <form onSubmit={onSignIn}>
                                                <div className="form-group">
                                                    <input type="text" value={username} className="form-control" id="email" name="username" aria-describedby="emailHelp" placeholder="Enter username" onChange={e=>setusername(e.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <input type="password" value={password} name="password" className="form-control" id="password" placeholder="Password" onChange={e=>setpassword(e.target.value)} />
                                                </div>
                                                
                                                <div className="custom-control custom-checkbox form-group">
                                                    <input ref={checkBoxRef} onClick={() => setrememberMe(!rememberMe)} type="checkbox" value={rememberMe} defaultChecked={rememberMe} className="custom-control-input" id="rememberMe" />
                                                    <label className="custom-control-label" htmlFor="rememberMe">{trans('Remember me')}</label>
                                                </div>
                                                
                                                <button type="submit" className="btn btn-primary btn-block btn-submit" disabled={isLoading_lg}>
                                                    {trans('login')} {isLoading_lg && (
                                                        <span className="spinner-border spinner-border-sm"></span>
                                                    )}
                                                </button>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block d-xl-block">
                                <div className="auth-image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


function mapStateToProps(state){
    return { 
        //myUser_id: state.user_id, //truyen len ham dung props token: this.props.myUser_id, đưa lưu từ redux to screen
        //myUrl: state.url,
        myToken: state.token,
    }
}

const action = {
    //mysetUserId: setUserId, //gan bien lưu kích hoạt this.props.mysetUserId(res.id); setUserId function bên action
    // mysetUserArray:setUserArray, 
    // mysetUrl_Action: setUrl_Action, 
    mysave_Token: save_Token, // mysave_Token khi biến, save_Token là action in redux (dùng để gán value khi thực thi)
    // mysetUsUsername: setUsUsername, 
    // mysetUsFullName: setUsFullName,
    // mysetUsUserLev: setUserLev,
    // menu_array: setMenuArray,
    // setUserModid: setUserModid
}

export default connect(mapStateToProps, action)(Login);
