import Loadable from 'react-loadable'

import BodyLayout from '../../components/layout/BodyLayout'
import LoadingPage from '../../components/layout/Loading'



const PosMainPage = Loadable({
  loader: () => import('../../pages/pos/Pos_main'),
  loading: LoadingPage,
})


const PosMainRoutes = [
  {
    path: '/pos_main',
    exact: true,
    requiredAuth: true,
    component: PosMainPage,
    layout: BodyLayout,
  }
]

export default PosMainRoutes
