import React, { useEffect } from 'react';
import { connect} from 'react-redux';
import { configAction } from '../../redux/actionCreators';
import  System_config_api  from "../../api/system/system_config.api";

const Footer = (props) =>{

  useEffect(() => {
    //get_data()
  },[]);

  const get_data = () => {
    System_config_api.get_config()
        .then(result => {
        if(result.data.success === true){
          const data_return = result.data.data.config;

          //var testObject = JSON.stringify(data_return).toString();

          const obj4 = data_return.reduce((accumulator, value, index) => {
            return {...accumulator, [value.config_name]: value.config_value};
          }, {});

          if (typeof window !== "undefined") {
            localStorage.setItem('config', JSON.stringify(obj4))
          }

          props.myconfigAction(JSON.stringify(obj4));
        }
    })
  }

  const yearNow = new Date().getFullYear();
  return (
    <div className="page-footer">
        <div className="row">
            <div className="col-md-12">
                <span className="footer-text">{yearNow} © stacks</span>
            </div>
        </div>
    </div>
  )
}

const  mapStateToProps = (state) => {
  return { }
}

const action = {
  myconfigAction:configAction, 
}

export default connect(mapStateToProps, action)(Footer);
//export default Footer;