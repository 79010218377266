import { useLocation } from 'react-router-dom'
import { BrowserRouter as  Link } from 'react-router-dom';

function Breadcrumb() {
  const location = useLocation();
  return(
    <div className="page-info">
      {/* <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item" aria-current="page">{location.pathname}</li>
          </ol>
      </nav> */}
    </div>
  )
}

export default Breadcrumb
