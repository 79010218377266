import decode from 'jwt-decode';
const loggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken() // Getting token from localstorage
  //return !!token && !isTokenExpired(token) // handwaiving header
  return !!token 
}

const isTokenExpired = (token) => {
  try {
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. 
          return true;
      }
      else
          return false;
  }
  catch (err) {
      console.log("expired check failed!");
      return false;
  }
}

const setToken = (idToken) => {
  // Saves user token to localStorage
  localStorage.setItem('token_localStorage', idToken)
}

const getToken = () => {
  // Retrieves the user token from localStorage
  return localStorage.getItem('token_localStorage')
}

const logout = () => {
  // Clear user token and profile data from localStorage
  localStorage.removeItem('token_localStorage');
  //props.mysave_Token('');
}

const getConfirm = () => {
  // Using jwt-decode npm package to decode the token
  let answer = decode(getToken());
  //console.log("Recieved answer!");
  return answer;
}



const authHeader = () =>{

  let token = '';
  if (typeof window !== "undefined") {
    //token = JSON.parse(localStorage.getItem("token_localStorage"));
    token = JSON.parse(getToken());
  }
  if (token) {
    if (loggedIn()) {
      return { 
        Authorization : token
      };
    }
  } else {
    return {};
  }
}

const HeaderToken = () =>{

  let token = '';
  if (typeof window !== "undefined") {
    token = JSON.parse(getToken());
  }
  if (token) {
    if (loggedIn()) {
      return token
    }
  } else {
    return {};
  }
}

const authHeader_form = () =>{

  let token = '';
  if (typeof window !== "undefined") {
    //token = JSON.parse(localStorage.getItem("token_localStorage"));
    token = JSON.parse(getToken());
  }
  if (token) {
    return { 
      token
    };
  } else {
    return {};
  }
}

const exp = {
  loggedIn,
  isTokenExpired,
  setToken,
  getToken,
  logout,
  getConfirm,
  authHeader, 
  HeaderToken,
  authHeader_form
};
export default exp;

//https://anonystick.com/blog-developer/su-dung-axios-interceptors-lam-moi-token-voi-refresh-token-trong-jwt-2020071757634479
//https://stackoverflow.com/questions/64576410/react-axios-interceptor-for-refresh-token