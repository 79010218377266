//import { notification } from 'antd'
import _ from 'lodash'
import Toastr from "../components/layout/Toastr";
import user_api from '../api/users/users.api';

const convertMessageError = (messages) => {
  if (_.isString(messages)) {
    return messages
  }

  let mes = ''
  _.forOwn(messages, (message) => {
    mes = message[0]
    return false
  })

  return mes
}

const handleError = (error) => {
  if (error) {
    Toastr.alert('error', convertMessageError(error.message));
  } else {
    Toastr.alert('error', 'No response!');
  }
}

let isRefreshing = false;
let requests = [];
export const errorException = (error, httpRequest) => {
  
  const statusCode = error.response?.status
  const config = error.config || {};
  //console.log(config.headers)
  switch (statusCode) {
    case 401:
      // TODO: logout
      //window.location.href = '/logout/';
      //console.log('Chuyen qua login hoac refresh token')
      //console.log('dd');

      //moi bo
      localStorage.removeItem('token_localStorageShipment');
      //window.location.replace("/login");
      return false;

      if (!isRefreshing) { // call lần đầu
          isRefreshing = true;
          //console.log('đ')
          return user_api.refreshToken()
          .then((res) =>{
              //const { accessToken = null } = res.data.token;
              const accessToken = JSON.stringify(res.data.token);
              //localStorage.setItem('token_localStorageShipment', accessToken)
              httpRequest.setToken(accessToken);
              if (config.headers) {
                config.headers.Authorization = `${accessToken}`;
              }
              requests.forEach((cb) => cb(accessToken));
              requests = [];
              return httpRequest(config);
          })
          .catch((res) => {
            console.log('refreshtoken error =>', res);
            //localStorage.removeItem('token_localStorageShipment');
            //window.location.href = '/login';
          })
          .finally(() => {
            isRefreshing = false;
        });
          
      }else{
        //console.log('g')
        return new Promise((resolve) => {
          requests.push((accessToken) => {
            config.baseURL = '';
            if (config.headers) {
              config.headers.Authorization = `${accessToken}`;
            }
            resolve(httpRequest(config));
          });
        });
      }

      Toastr.alert('error', '401 auth token');
      break

    case 403:
      Toastr.alert('error', '403 Forbidden');
      break

    case 404:
      Toastr.alert('error', '404 Not Found');
      break

    case 422:
      handleError(error.response?.data)
      break

    case 500:
      Toastr.alert('error', '500 Internal Server Error');
      break

    default:
      Toastr.alert('error', 'Something is wrong');
      break
  }

  return Promise.reject(error.response?.data)
}
