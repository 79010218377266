import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap'
import UserAvatar from 'react-user-avatar'
import AuthHelperMethods from '../../helpers/AuthHelperMethods';
import System_lib from '../../libs/System_lib';

import shipment_api from '../../api/shipment/shipment.api';


const Header = (props) => {
    const Auth = new AuthHelperMethods();
    const system_lib = new System_lib();
    const [showDropDown, setshowDropDown] = useState(false);

    const reload_page = () => {
        window.location.reload()
    }
    const us = Auth.getConfirm();

    const showDropdown = () => {
        setshowDropDown(!showDropDown)
    }
    const [showHideModal, setshowHideModal] = useState(false);
    const [email, setemail] = useState('');
    const [noti, setnoti] = useState('');
    const [isLoading_lg, setisLoading_lg] = useState(false);

    const className_liDropDown = showDropDown ? 'nav-item nav-profile dropdown show' : 'nav-item nav-profile dropdown';
    const dropdown_menu = showDropDown ? 'dropdown-menu show' : 'dropdown-menu';
    const aria_expanded = showDropDown ? true : false

    const ref_infoUser = useRef();

    useEffect(() => {
        if(us){
            if (us.result.email === '' || us.result.email === null || us.result.email === undefined) {
                toggleModal()
            }
        }
        if (typeof window !== "undefined") {
            if(us){
                const version_app_local = localStorage.getItem('version_app')
                const version_app = (version_app_local === null)? 1: parseFloat(version_app_local)
                if(parseFloat(us.version_app) > version_app){
                    system_lib.emptyCache();
                    localStorage.setItem('version_app', parseFloat(us.version_app))
                }
            }
        }
    }, [])

    useOnClickOutside(ref_infoUser, () => setshowDropDown(false));

    function useOnClickOutside(ref, handler) {
        useEffect(
            () => {
                const listener = (event) => {
                    if (!ref.current || ref.current.contains(event.target)) {
                        return;
                    }
                    handler(event);
                };
                document.addEventListener("mousedown", listener);
                document.addEventListener("touchstart", listener);
                return () => {
                    document.removeEventListener("mousedown", listener);
                    document.removeEventListener("touchstart", listener);
                };
            },
            [ref, handler]
        );
    }

    const _handleLogout = () => {
        Auth.logout();
        props.history.replace('/');
    }

    const toggleModal = () => {
        setshowHideModal(!showHideModal);
    }

    const showrequired = (value) => {
        return (
            <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert" onClick={e => setnoti('')} >×</a>
                <strong>Error!</strong>
                <p>{value}</p>
            </div>
        );
    }

    const update_email = () => {
        setisLoading_lg(true);
        const data = {email}
        shipment_api.update_email(data)
            .then(result => {
                setisLoading_lg(false);
                if(result.data.success === true){
                    Auth.setToken(JSON.stringify(result.data.value.token));
                    Auth.setTokenRefesh(JSON.stringify(result.data.value.tokenRefesh))
                    window.location.replace('/');
                }else{
                    setnoti(showrequired((result.data.alert)))
                }
            })
    }

    return (
        <>
            {us !== null &&
                <>
                    <div className="page-header">
                        <nav className="navbar navbar-expand page-shipment">
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <ul className="navbar-nav">
                                <li className={className_liDropDown} onClick={showDropdown}>
                                    <a className="nav-link dropdown-toggle head-img-shipment" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded={aria_expanded}>
                                        <UserAvatar className="text-center" size="38" name={us.result.fullname !== undefined ? us.result.fullname : 'nitsoft'} src={(us.result.image !== '' ? us.result.image : '')} />
                                        <span>{us.result.fullname}</span>
                                        <i className="material-icons dropdown-icon">keyboard_arrow_down</i>
                                    </a>
                                    <div className={dropdown_menu} aria-labelledby="navbarDropdown" ref={ref_infoUser}>
                                        <Link className="dropdown-item" to="/shipment/change_info">Đổi thông tin</Link>
                                        <div className="dropdown-divider"></div>
                                        <Link className="dropdown-item" to="/" onClick={_handleLogout}>Thoát</Link>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/" style={{ 'display': 'flex' }}><i className="material-icons-outlined mgr5">maps_home_work</i> Home</Link>
                                </li>
                                <li className="nav-item">
                                    <a onClick={reload_page} style={{ 'display': 'flex' }} href="#" className="nav-link"><i className="material-icons-outlined">refresh</i> Tải lại trang</a>
                                </li>
                            </ul>

                        </nav>
                    </div>

                    <Modal className={'modal-media-action modal-confirm'} show={showHideModal} animation={true} size="sm" backdropClassName={"backdropmodal-confirm"}>
                        
                        <Modal.Body style={{ 'padding': '1rem' }}>
                            {noti && <div>{noti}</div>}
                            <div className="form-group row">
                                <div className="col-12">
                                    <label htmlFor="email" className="control-label">Email <span className="text-danger">*</span></label>
                                    <Form.Control type="email" required name="email" value={email} onChange={(e) => setemail(e.target.value)} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="line"></div>
                            <Button variant="success" onClick={update_email} disabled={isLoading_lg}>
                                <i className="fa fa-check"></i> Cập nhật 
                                {isLoading_lg && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </>
            }
        </>
    )
}



export default Header;