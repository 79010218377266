import React from 'react';
import { Link } from 'react-router-dom';

class Page404 extends React.Component
{
	componentWillMount() {
        document.title = "Page 404";
    }
	render()
	{
	    return (
	        <div className="error-page error-404">
	        	<div className="connect-container align-content-stretch d-flex flex-wrap">
					<div className="container d-flex align-content-stretch flex-wrap">
					    <div className="row">
					      <div className="col d-flex align-content-stretch flex-wrap">
					        <div className="error-page-image" />
					        <div className="error-page-text">
					          <h3>Oops.. Something went wrong</h3>
					          <p>It seems that the page you are looking for no longer exists.<br />We will try our best to fix this soon.</p>
					          <div className="error-page-actions">
					            <a href="#" className="btn btn-secondary mdr10">Help Center</a>
					            <Link to="/" className="btn btn-primary">Homepage</Link>
					          </div>
					        </div>
					      </div>
					    </div>
					</div>
				</div>
	        </div>
	    )
	}
}
export default Page404;