//bo
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AuthHelperMethods from '../../helpers/AuthHelperMethods';


const HeaderPos = (props) => {
    const Auth = new AuthHelperMethods();
    const [isLoading, setisLoading] = useState(false);
	const [loading_action, setloading_action] = useState(false);

    const [showDropDown, setshowDropDown] = useState(false);
    const history = useHistory();

    const _handleLogout = () => {
        this.Auth.logout();
        history.replace("/login");
    }
    
    const reload_page = () =>{
        window.location.reload()
    }
    const us = Auth.getConfirm();

    const showDropdown = () =>{
        setshowDropDown(!showDropDown)
    }
    const className_liDropDown = showDropDown ? 'nav-item nav-profile dropdown show': 'nav-item nav-profile dropdown';
    const dropdown_menu = showDropDown ? 'dropdown-menu show' : 'dropdown-menu';
    const aria_expanded = showDropDown ? true : false
    return(
        <>
        <div className="page-header">
            <nav className="navbar navbar-expand">
                
                <ul className="navbar-nav">
                    <li>
                        <div className="navbar-search">
                            <form>
                                <div className="form-group">
                                    <input type="text" name="search" id="nav-search" placeholder="Search..." />
                                </div>
                            </form>
                        </div>
                    </li>
                    
                </ul>
                <div className="collapse navbar-collapse" id="navbarNav">
                    
                </div>
                <div className="navbar-search">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/home" className="nav-link"><i className="material-icons-outlined">dashboard</i> Doashboard gg</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        </>
    )
}

export default HeaderPos;