import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';

const RenderMenu = (props) => {
  const [activeTab, setActiveTab] = useState(null);
  const location = useLocation();

  const onTitleClick = (index) => {
    if(activeTab === null)
      setActiveTab(index);
    else if(index !== activeTab)
      setActiveTab(index);
    else
      setActiveTab(null);
  };

  const segment_url = location.pathname.split("/");


  const renderedItems = props.items.map((item, index) => {
    
    const activeStatus_className = index === activeTab ? 'open' : '';
    const activeStatus = index === activeTab ? true : false;
    const check_submenu = (Array.isArray(item.data_routes) && item.data_routes.length)? true:false

    const active_class_child = (item.Segment ===  segment_url[2])?'active-page open':''
    
    const check_openSegment = () => {
      if(props.isActive === true){
        if(activeStatus === true){
          return true
        }
        return false
      }else{
        if(active_class_child !== ''){
          return true
        }else if(activeStatus === true){
          return true
        }else{
          return false
        }
      }      
    }

    return (
      <React.Fragment key={item.MID}>
      
        <li className={active_class_child+' '+activeStatus_className} key={item.id} onClick={() => onTitleClick(index)}>
            <Link to={(check_submenu === true)?"#!":'/admin/'+item.Segment } className="nav-link">
            <i className="material-icons-outlined">{item.code_icon}</i>{item.MoName} 
            { (check_submenu === true)?
                <i className="material-icons has-sub-menu">add</i> : ''
            }
            </Link>
            {
            (check_submenu === true) &&
              <ul className={"sub-menu"} style={check_openSegment()?{display:"block"}:{display:"none"}}>
                  {
                    item.data_routes?.map((it, i) => {
                      return (
                        <React.Fragment key={i}>
                          <li key={i}>
                              <Link to={'/'+it.Url} className="nav-link">{it.Title}</Link>
                          </li>
                        </React.Fragment>
                      )
                    })
                  }
              </ul>
            }
           
        </li>
      </React.Fragment>
    );
  });

  return (
    <>{renderedItems}</>
  );
};

export default RenderMenu;
