import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import store from './redux/Store';
import reportWebVitals from './reportWebVitals';
import { history } from "./helpers/history";
import AppRoutes from './components/Application/AppRoutes'
import route from './routes'


ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<AppRoutes routes={route} />
		</Router>
	</Provider>,
  	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


reportWebVitals();
