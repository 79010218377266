import React from 'react';
import { BrowserRouter as  Link, useHistory } from 'react-router-dom';
import AuthHelperMethods from '../../helpers/AuthHelperMethods';

import HeaderPos from './HeaderPos'; //Include Heder

const PosLayout = ({ children, userInfo, routes }) => {
  const Auth = new AuthHelperMethods();
  
  const history = useHistory();

  return (
    <>
    <div className="connect-container">
      <div className="page-container_pos">
        <HeaderPos history={history} />
        {children}
      </div>    
    </div>
    </>
  )
}



export default PosLayout
