import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'

const BackupList = Loadable({
  loader: () => import('../../pages/backup/Backup_list'),
  loading: LoadingPage,
})


const backupRoutes = [
  {
    path: '/admin/backup',
    exact: true,
    requiredAuth: true,
    component: BackupList,
    layout: MainLayout
  }
]

export default backupRoutes
