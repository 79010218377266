//import PageNotFound from '../pages/Error/NotFound'
import PageNotFound from '../pages/Error/Page404'
import GuestLayout from '../components/layout/GuestLayout'
import MainLayout from '../components/layout/MainLayout'
import PosLayout from '../components/layout/PosLayout'
import BodyLayout from '../components/layout/BodyLayout'
import ShipmentLayout from '../components/layout/ShipmentLayout'

/**
 * Get List Route Modules.
 *
 * @returns {[]}
 */
const publicRoutes = require.context('./public', true, /\.js$/)
const privateRoutes = require.context('./private', true, /\.js$/)
const posRoutes = require.context('./pos', true, /\.js$/)
const bodyRoutes = require.context('./body', true, /\.js$/)
const shipmentRoutes = require.context('./shipment', true, /\.js$/)

const parseModules = (context) => {
  const routes = []
  const paths = []

  context.keys().forEach((fileName) => {
    const object = context(fileName).default
    routes.push(...object)
    paths.push(...object.map((o) => o.path))
  })

  return {
    routes,
    paths,
  }
}

const routes = [
  {
    path: parseModules(publicRoutes).paths,
    exact: true,
    requiredAuth: false,
    requiredCus: false,
    restricted: true,
    component: GuestLayout,
    routes: parseModules(publicRoutes).routes,
  },
  {
    path: parseModules(privateRoutes).paths,
    exact: true,
    requiredAuth: true,
    requiredCus: false,
    restricted: false,
    component: MainLayout,
    routes: parseModules(privateRoutes).routes,
  },
  {
    path: parseModules(posRoutes).paths,
    exact: true,
    requiredAuth: true,
    requiredCus: false,
    restricted: false,
    component: PosLayout,
    routes: parseModules(posRoutes).routes,
  },
  {
    path: parseModules(bodyRoutes).paths,
    exact: true,
    requiredAuth: true,
    requiredCus: false,
    restricted: false,
    component: BodyLayout,
    routes: parseModules(bodyRoutes).routes,
  },
  {
    path: parseModules(shipmentRoutes).paths,
    exact: true,
    requiredAuth: false,
    requiredCus: true,
    restricted: false,
    component: ShipmentLayout,
    routes: parseModules(shipmentRoutes).routes,
  },
  {
    path: '*',
    component: PageNotFound,
  },
]

export default routes
