import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'

const mediaPage = Loadable({
  loader: () => import('../../pages/media/List_media'),
  loading: LoadingPage,
})

const testScrollPage = Loadable({
  loader: () => import('../../pages/media/Test_scroll'),
  loading: LoadingPage,
})

const mediaRoutes = [
  {
    path: '/media',
    exact: true,
    requiredAuth: true,
    component: mediaPage,
    layout: MainLayout,
  },
  {
    path: '/media/:id',
    exact: true,
    requiredAuth: true,
    component: mediaPage,
    layout: MainLayout,
  },
  {
    path: '/media/ng/test_scroll',
    exact: true,
    requiredAuth: true,
    component: testScrollPage,
    layout: MainLayout,
  }
]

export default mediaRoutes
