import axios from 'axios'
import { REQUEST_HEADER } from '../config/constants'
import { errorException } from './handlerShipment'
import authHeader_token from "../api/auth-header-token";


const API_URL = process.env.REACT_APP_API_ENDPOINT;

const httpRequest = axios.create({
  timeout: 300000,
  baseURL: API_URL,
  headers: REQUEST_HEADER,
})

httpRequest.setToken = (token) => {
  localStorage.setItem('token_localStorageShipment', token)
};

const ISSERVER = typeof window === 'undefined';
httpRequest.interceptors.request.use(
  (config) => {
    if (!ISSERVER) {
      const token = authHeader_token.HeaderToken()
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `${token}`;
      }
    }
    return config
  },
  (error) => {
    console.log('-----loi-----');
    Promise.reject(error)
  }
)


// let isRefreshing = false;
// let requests = [];

httpRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async(error) => {
    return errorException(error, httpRequest)
  }
);

export default httpRequest
