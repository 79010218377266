//import AuthHelperMethods from '../helpers/AuthShipmentHelperMethods';
import AuthHelperMethods from '../helpers/AuthHelperMethods';

const Auth = new AuthHelperMethods();
export const checkPermission = (userInfo) => {
  if(Auth.getConfirm().result.cus_log === false){
    return false
  }
  else{
    return true;
  }
}

export const hasAuth = (userInfo) => {
  //if(Auth.checkLogin() && Auth.getConfirm().result.cus_log === true)
  if(Auth.checkLogin_permissionShipment() === true){
    return true
  }else{
    return false;
  }
}
