import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/orders/Orders_list'),
  loading: LoadingPage,
})

const PageOrdersAction = Loadable({
  loader: () => import('../../pages/orders/Orders_action'),
  loading: LoadingPage,
})

const PageOrdersCheck = Loadable({
  loader: () => import('../../pages/orders/Orders_check'),
  loading: LoadingPage,
})

const PageOrdersCheckMain = Loadable({
  loader: () => import('../../pages/orders/Orders_check_main'),
  loading: LoadingPage,
})

const PageOrdersPaymentsAction = Loadable({
  loader: () => import('../../pages/orders/Orders_payments_action'),
  loading: LoadingPage,
})

const PageOrdersView = Loadable({
  loader: () => import('../../pages/orders/Orders_view'),
  loading: LoadingPage,
})

const PageOrdersEdit = Loadable({
  loader: () => import('../../pages/orders/Orders_edit'),
  loading: LoadingPage,
})


const PageOrdersAdd = Loadable({
  loader: () => import('../../pages/orders/Orders_add'),
  loading: LoadingPage,
})


const Routes = [
  {
    path: '/admin/orders',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/action',
    exact: true,
    requiredAuth: true,
    component: PageOrdersAction,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/check_main/:id',
    exact: true,
    requiredAuth: true,
    component: PageOrdersCheckMain,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/check_main/:id/:productid',
    exact: true,
    requiredAuth: true,
    component: PageOrdersCheckMain,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/payments_action',
    exact: true,
    requiredAuth: true,
    component: PageOrdersPaymentsAction,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/payments_action/:id',
    exact: true,
    requiredAuth: true,
    component: PageOrdersPaymentsAction,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/orders_view/:id',
    exact: true,
    requiredAuth: true,
    component: PageOrdersView,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/orders_edit/:id',
    exact: true,
    requiredAuth: true,
    component: PageOrdersEdit,
    layout: MainLayout,
  },
  {
    path: '/admin/orders/orders_add',
    exact: true,
    requiredAuth: true,
    component: PageOrdersAdd,
    layout: MainLayout,
  }
]

export default Routes
