import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'


const PageMain = Loadable({
  loader: () => import('../../pages/trahang/Trahang_list'),
  loading: LoadingPage,
})



const Routes = [
  {
    path: '/trahang',
    exact: true,
    requiredAuth: true,
    component: PageMain,
    layout: MainLayout,
  }
]

export default Routes
