import Loadable from 'react-loadable'

import PosLayout from '../../components/layout/PosLayout'
import LoadingPage from '../../components/layout/Loading'

const PosPage = Loadable({
  loader: () => import('../../pages/pos/Pos'),
  loading: LoadingPage,
})

const PosRoutes = [
  {
    path: '/pos',
    exact: true,
    requiredAuth: true,
    component: PosPage,
    layout: PosLayout,
  },
]

export default PosRoutes
