import Loadable from 'react-loadable'

import MainLayout from '../../components/layout/MainLayout'
import LoadingPage from '../../components/layout/Loading'

const systemPage = Loadable({
  loader: () => import('../../pages/test/test_11/Test2'),
  loading: LoadingPage,
})

const systemRoutes = [
  {
    path: '/test',
    exact: true,
    requiredAuth: true,
    component: systemPage,
    layout: MainLayout,
  },
]

export default systemRoutes
