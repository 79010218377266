import { combineReducers } from 'redux';

import tokenReducer from './tokenReducer';
import configReducer from './configReducer';

const reducer = combineReducers({
	token: tokenReducer,
	config: configReducer
})

export default reducer;