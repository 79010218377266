import React from 'react';
//import { BrowserRouter as  Link, useHistory } from 'react-router-dom';
//import AuthHelperMethods from '../../helpers/AuthHelperMethods';

const BodyLayout = ({ children}) => {
  //const Auth = new AuthHelperMethods();
  //const history = useHistory();

  return (
    
    <div className="connect-container">
      <div className="page-container_pos">
        {children}
      </div>    
    </div>
    
  )
}



export default BodyLayout
